import { useEffect } from "react";

export default function Login() {

    useEffect(() => {
        if (window.location.port !== "3000") window.location.href = "/autologin.php";
    });

    return (
        <div className="Login">
        </div>
    );
}