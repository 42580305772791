interface iIcon {
    type?: 'regular' | 'solid';
    name: String;
}

export default function Icon({ type = 'solid', name }: iIcon) {

    const classes = [];
    if (type === 'regular') classes.push('fa-regular');
    if (type === 'solid') classes.push('fa-solid');
    classes.push('fa-' + name);

    return <i className={classes.join(' ')}></i>
}