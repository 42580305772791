const dateFormatter = Intl.DateTimeFormat("de-DE", {
    year: "numeric", month: "long", day: "2-digit"
});

const timeFormatter = Intl.DateTimeFormat("de-DE", {
    hour: "2-digit", minute: "2-digit"
});

const replacements = [
    ["Ä", "Ae"],
    ["Ö", "Oe"],
    ["Ü", "Ue"],
    ["ä", "ae"],
    ["ö", "oe"],
    ["ü", "ue"],
    ["ß", "ss"]
];

export function cleanName(name: string): string {
    let cleaned = name;
    replacements.forEach(([search, replacement]) => {
        cleaned = cleaned.replaceAll(search, replacement);
    });
    return cleaned;
}

export function displayDateFromISO10String(dateString: string): string {
    try {
        const date = new Date(dateString);
        return dateFormatter.format(date);
    }
    catch (e) {
        return dateString;
    }
}

export function displayTimeFromDateTimeString(dateTimeString: string): string {
    try {
        const date = new Date(dateTimeString);
        return timeFormatter.format(date);
    }
    catch (e) {
        return dateTimeString;
    }
}

export function euros(n: number | undefined) {
    return (n || 0).toFixed(2).replace('.', ',').replace(',00', ',-') + " €";
}

export function byDate(datekey: string, ascending: boolean = true) {
    return function (a: any, b: any) {
        const aTimestamp = new Date(a[datekey]).getTime();
        const bTimestamp = new Date(b[datekey]).getTime();
        return ascending ? aTimestamp - bTimestamp : bTimestamp - aTimestamp;
    }
}