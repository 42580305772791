import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from './app/hooks';
import { loadSFAuthed, loadUser, selectAuthed, setClassicLinks } from './app/appSlice';
import { loadAkteneingaenge } from './app/eingaengeSlice';
import useCookie from './app/useCookie';

import Login from './routes/Login';
import { Layout } from './routes/Layout';
import Table from './routes/Table';
import { Deadlines } from './routes/Deadlines';


import './App.css';

const notAuthedRouter = createBrowserRouter([
  {
    path: '*',
    element: <Login />
  }
]);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Table />,
      },
      {
        path: 'fristen/:bffid',
        element: <Deadlines />
      }
    ]
  },
]);

function App() {

  const dispatch = useAppDispatch();
  const authed = useAppSelector(selectAuthed);

  const classicLinksCookie = useCookie("sf-classic-links");

  useEffect(() => {
    if (authed === null) {
      dispatch(loadSFAuthed());
    }
    if (authed === true) {
      dispatch(loadUser());
      dispatch(loadAkteneingaenge());
    }
  }, [dispatch, authed]);

  useEffect(() => {
    const classic = classicLinksCookie.get();
    if (classic === undefined) classicLinksCookie.set("yes");
    dispatch(setClassicLinks(classic !== "no"));
  }, [classicLinksCookie, dispatch]);

  if (authed === null) return null;

  return (
    <div className='App'>
      <RouterProvider router={authed ? router : notAuthedRouter} />
    </div>
  );
}

export default App;
