import './SelectBar.css';

interface iSelectBar {
    value: string | number;
    onChange: (val: string | number) => void;
    options: (string | number)[];
}

export default function SelectBar({ value, onChange, options }: iSelectBar) {
    return (
        <div className="SelectBar">
            {options.map(val => {

                const classes = [];
                if (val === value) classes.push("active");

                return (
                    <button key={val} className={classes.join(" ")} onClick={_ => onChange(val)}>{val}</button>
                );
            })}
        </div>
    );
}