export default function useCookie(name: string) {

    function get(): string | undefined {
        const cookieValue = document.cookie.split('; ')
            .find((row) => row.startsWith(`${name}=`))
            ?.split('=')[1];
        if (cookieValue) return decodeURIComponent(cookieValue);
        return undefined;
    }

    function set(value: string, expirationDays: number = 30) {
        const seconds = expirationDays * 24 * 60 * 60;
        document.cookie = `${name}=${encodeURIComponent(value)};Path=/;max-age=${seconds};SameSite=Lax`;
    }

    function remove() {
        set(name, -666);
    }

    return { get, set, remove };
};