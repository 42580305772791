import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { iSFDeadline } from "../interfaceSFClient";
import api from "../API";

import { Card } from "../components/Card";
import { cleanName, displayDateFromISO10String, euros } from "../util";

import './Deadlines.css';
import Icon from "../components/Icon";
import SelectBar from "../components/SelectBar";
import IconButton from "../components/IconButton";
import { useAppSelector } from "../app/hooks";
import { selectClassicLinks } from "../app/appSlice";


const cols = [
    {
        label: "Kundennr.",
        key: "Kundennummer_ABV"
    },
    {
        label: "Kunde",
        key: "LastName"
    },
    {
        label: "Geburtstag",
        key: "Birthday"
    },
    {
        label: "Behörde",
    },
    {
        label: "Frist",
        key: "Frist",
        date: true
    },
    {
        label: "Akteneingang",
        key: "Akteneingang",
        date: true
    },
    {
        label: "Aktenausgang",
        key: "Aktenausgang",
        date: true
    },
    {
        label: "Offen",
        key: "Offen"
    }
];

function DeadlineRow({ data }: { data: iSFDeadline }) {

    const classicCookie = useAppSelector(selectClassicLinks);

    const { Id, Kundennummer_ABV, Kunde_Id, LastName, FirstName, Birthday, Agency, Frist, Akteneingang, Aktenausgang, Endbetrag, Eingegangen, Offen } = data;

    const urlAccount = classicCookie ?
        `https://d24000000xrg4eac.my.salesforce.com/` + Kunde_Id :
        `https://d24000000xrg4eac.lightning.force.com/lightning/r/Account/${Kunde_Id}/view`;
    const urlService = classicCookie ?
        `https://d24000000xrg4eac.my.salesforce.com/` + Id :
        `https://d24000000xrg4eac.lightning.force.com/lightning/r/Kundendienstleistung_ABV__c/${Id}/view`


    const agencyStyle = { maxWidth: 300, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" };

    const owedTitle = `Betrag: ${euros(Endbetrag)} - Eingegangen: ${euros(Eingegangen)}`

    return (
        <tr>
            <td>{Kundennummer_ABV}</td>
            <td><a href={urlAccount} target="_blank" rel="noreferrer">{LastName}, {FirstName}</a></td>
            <td>{Birthday}</td>
            <td style={agencyStyle} title={Agency}>{Agency || "-"}</td>
            <td><a href={urlService} target="_blank" rel="noreferrer">{Frist ? displayDateFromISO10String(Frist) : "-"}</a></td>
            <td>{Akteneingang ? displayDateFromISO10String(Akteneingang) : "-"}</td>
            <td>{Aktenausgang ? displayDateFromISO10String(Aktenausgang) : "-"}</td>
            <td title={owedTitle}>{Offen ? euros(Offen) : "-"}</td>
        </tr>
    );
}

export function Deadlines() {

    const { bffid } = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [deadlines, setDeadlines] = useState<iSFDeadline[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [monthsBack, setMonthsBack] = useState<number>(1);

    const [by, setBy] = useState<string>("Frist");
    const [asc, setAsc] = useState<boolean>(true);

    useEffect(() => {
        if (!bffid) {
            navigate("/");
            return;
        }
        async function loader() {
            const result = await api.loadFristen(bffid!, monthsBack * 30);
            if (result.success) {
                setDeadlines(result.fristen);
                setLoaded(true);
            }
        }
        if (!loaded && deadlines.length === 0) loader();
    });

    useEffect(() => {
        async function loader() {
            const result = await api.loadFristen(bffid!, monthsBack * 30);
            if (result.success) {
                setDeadlines(result.fristen);
            }
        }
        setDeadlines([]);
        loader();
    }, [bffid, monthsBack])

    function setSorting(newBy: string) {
        if (newBy === by) {
            setAsc(current => !current);
        }
        else {
            setBy(newBy);
            setAsc(true);
        }
    }

    const filteredDeadlines = searchFilter.length
        ? deadlines.filter(
            ({ LastName, FirstName, Birthday }) => {
                const sf = searchFilter.toLocaleLowerCase();
                return [LastName, FirstName, Birthday].map(s => s.toLocaleLowerCase()).some(s => s.includes(sf));
            })
        : deadlines;

    const sortedDeadlines = [...filteredDeadlines].sort((a, b) => {
        let aProp = (a as any)[by];
        let bProp = (b as any)[by];
        const col = cols.find(col => col.key === by);
        if (col && col.date) {
            aProp = new Date(aProp).getTime();
            bProp = new Date(bProp).getTime();
        }
        if (by === "LastName") {
            aProp = cleanName(aProp);
            bProp = cleanName(bProp);
        }
        return aProp < bProp ? (asc ? -1 : 1) : (asc ? 1 : -1);
    });

    if (!loaded) return (
        <Card><p>Liste wird geladen...</p></Card>
    );

    return (
        <div className="Deadlines FlexColumn">
            <div className="Tools FlexList" style={{ justifyContent: "space-between" }}>
                <div>
                    Suche: <input value={searchFilter} onChange={e => setSearchFilter(e.target.value)} disabled={deadlines.length < 2} />
                    {" "}
                    {searchFilter.length > 0 && <IconButton name="times" onClick={_ => setSearchFilter("")} />}
                </div>
                <div className="FlexList Center">
                    Zurückliegende Monate:
                    <SelectBar options={[0, 1, 2]} value={monthsBack} onChange={i => setMonthsBack(i as number)} />
                </div>
            </div>
            {deadlines.length === 0 ?
                <Card><p>Keine Vorgänge im Zeitraum</p></Card> :
                <table cellSpacing={0} cellPadding="6pt">
                    <thead>
                        <tr>
                            {cols.map(({ label, key }) => {
                                if (!key) return <th key={label}>{label}</th>;
                                return (
                                    <th key={label} onClick={_ => setSorting(key)} style={{ cursor: "pointer" }}>
                                        <div className="FlexList" style={{ flexWrap: "nowrap" }}>
                                            <span>{label}</span>
                                            {key !== by && <span style={{ display: "inline-block", width: 16 }}> </span>}
                                            {<span style={{ display: key === by && asc ? "inline" : "none" }}><Icon name="chevron-up" /></span>}
                                            {<span style={{ display: key === by && !asc ? "inline" : "none" }}><Icon name="chevron-down" /></span>}
                                        </div>
                                    </th>)
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedDeadlines.map((data) => <DeadlineRow key={data.Id} data={data} />)}
                    </tbody>
                </table>
            }
        </div>
    );

}