import { Link, Outlet, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAuthed, selectClassicLinks, setClassicLinks } from '../app/appSlice';
import { selectAkteneingaenge } from '../app/eingaengeSlice';
import useCookie from '../app/useCookie';

import Icon from '../components/Icon';
import { SFStatus } from '../components/SFStatus';

import ABVLogo from '../images/logo_abv.png';

import './Layout.css';

export function Layout() {

    const { bffid } = useParams();

    const data = useAppSelector(selectAkteneingaenge);
    const authed = useAppSelector(selectAuthed);
    const classicLinks = useAppSelector(selectClassicLinks);

    const classicLinksCookie = useCookie("sf-classic-links");

    const dispatch = useAppDispatch();

    const bff = bffid && data && data.find((ae) => ae.Id === bffid);


    function handleClassicChange(checked: boolean) {
        dispatch(setClassicLinks(checked));
        classicLinksCookie.set(checked ? "yes" : "no");
    }

    return (
        <div className='Layout-Wrap'>
            <div className='App-Top'>
                <div className='App-Top-Left'>
                    <Link to="/">
                        <img className="App-Logo" src={ABVLogo} width="90%" alt="ABV GmbH" />
                    </Link>
                </div>
                <div className="App-Top-Center">
                    {authed &&
                        <div>
                            <input type="checkbox" checked={classicLinks} id="useclassic"
                                onChange={e => handleClassicChange(e.target.checked)}
                            /> <label htmlFor='useclassic'>SF Classic verwenden</label>
                        </div>
                    }
                    {bff && <div className='FlexList' style={{ justifyContent: "start", fontSize: "120%" }}>
                        <Link to="/"><Icon name="chevron-left" /></Link>
                        {" "}
                        {bff.Name}
                    </div>
                    }
                </div>
                <div className="App-Top-Right">
                    <SFStatus />
                </div>
            </div>
            <main>
                <Outlet />
            </main>
        </div>
    );
};