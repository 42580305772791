import api from "../API";
import { selectAuthed, selectUser } from "../app/appSlice";
import { useAppSelector } from "../app/hooks";

import SFLogo from '../images/salesforce-logo.svg';
import IconButton from "./IconButton";

import './SFStatus.css';

export function SFStatus() {

    const authed = useAppSelector(selectAuthed);
    const user = useAppSelector(selectUser);

    async function handleLogout() {
        const result = await api.logout();
        if (result.success) window.location.href = result.logoutUrl;
    }

    return (
        <div className="SFStatus">
            <div className="Content FlexColumn" style={{ gap: 0 }}>
                <div className="FlexList" style={{ marginBottom: 8 }}>
                    <img src={SFLogo} alt="Salesforce" />
                    <IconButton name="right-from-bracket" light onClick={handleLogout} />
                </div>
                {authed === null && <span>Verbinden...</span>}
                {authed === false && <span>Nicht verbunden</span>}
                {authed && user && <span>Verbunden als</span>}
                {authed && user && <span>{user.Name}</span>}
            </div>
        </div>
    );
}