import { useEffect, useState } from "react";

import { useAppSelector } from "../app/hooks";
import { selectAkteneingaenge } from "../app/eingaengeSlice";
import { iSFAkteneingaenge, iSFWeek } from "../interfaceSFClient";

import './Table.css';
import { Link } from "react-router-dom";

interface iTableRow {
    ae: iSFAkteneingaenge
}

function TableRow({ ae }: iTableRow) {

    const { Name, Id, Wochen } = ae;

    return (
        <tr key={Name}>
            <td><Link to={"/fristen/" + Id}>{Name}</Link></td>
            {Wochen.map(({ Woche, Eingaenge }) => <td key={Woche}>{Eingaenge}</td>)}
        </tr>
    );

}

export default function Table() {

    const eingaenge = useAppSelector(selectAkteneingaenge);

    const [kWs, setkWs] = useState<number[]>([]);
    const [tableData, setTableData] = useState<iSFAkteneingaenge[]>([]);

    useEffect(() => {
        if (eingaenge.length > 0 && tableData.length === 0) {
            let thisWeek = 0;
            eingaenge.forEach(({ Wochen }) => {
                Wochen.forEach(({ Woche }) => {
                    if (Woche > thisWeek) thisWeek = Woche;
                });
            });
            const kws: number[] = [];
            for (let Woche = thisWeek - 3; Woche <= thisWeek; Woche++) {
                kws.push(Woche > 0 ? Woche : Woche + 53);
            }
            const data = eingaenge.map(({ Name, Id, Wochen }) => {
                const newWochen: iSFWeek[] = [];
                kws.forEach(Woche => {
                    const t = {
                        Woche,
                        Eingaenge: 0
                    };
                    const existing = Wochen.find(({ Woche: _Woche }) => _Woche === Woche);
                    if (existing) t.Eingaenge = existing.Eingaenge;
                    newWochen.push(t);
                });
                return {
                    Name,
                    Id,
                    Wochen: newWochen
                };
            });
            setkWs(kws);
            setTableData(data);
        }
    }, [eingaenge, tableData.length]);

    const topEntries = ["BfF München", "BfF Düsseldorf"];

    return (
        <div className="Eingaenge FlexColumn" style={{ alignItems: "center" }}>
            <h2>Akteneingänge nach KW</h2>
            <table cellSpacing={0} cellPadding="12pt">
                <thead>
                    <tr>
                        <th>Begutachtungsstelle</th>
                        {kWs.map(kw => <th key={kw}>KW {kw}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {tableData.filter(({ Name }) => topEntries.includes(Name)).map((ae) => <TableRow key={ae.Id} ae={ae} />)}
                    {tableData.filter(({ Name }) => !topEntries.includes(Name)).map((ae) => <TableRow key={ae.Id} ae={ae} />)}
                </tbody>
            </table>
        </div>
    );
}