import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './store'
import api from '../API';
import { iSFUser } from '../interfaceSFClient';

// Define a type for the slice state
interface iAppState {
    authed: null | boolean;
    user?: iSFUser;
    classicLinks?: boolean;
    snackMessage: string | null;
    snackTimeout?: number;
}

// Define the initial state using that type
const initialState: iAppState = {
    authed: null,
    snackMessage: null,
}

interface iSnackMessage {
    message: string | null;
    timeout?: number;
}

export const appSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAuthed: (state, action: PayloadAction<boolean>) => {
            state.authed = action.payload;
        },
        setUser: (state, action: PayloadAction<iSFUser>) => {
            state.user = action.payload;
        },
        setClassicLinks: (state, action: PayloadAction<boolean>) => {
            state.classicLinks = action.payload;
        },
        setSnackMessage: (state, action: PayloadAction<iSnackMessage>) => {
            state.snackMessage = action.payload.message;
            state.snackTimeout = action.payload.timeout || 3000;
        },
    },
})

export const { setAuthed, setClassicLinks, setSnackMessage } = appSlice.actions;

export function loadSFAuthed() {
    return async function (dispatch: AppDispatch) {
        const result = await api.sfauth();
        if (result.success) dispatch(appSlice.actions.setAuthed(result.valid));
    };
}

export function loadUser() {
    return async function (dispatch: AppDispatch) {
        const result = await api.loadUser();
        if (result.success) dispatch(appSlice.actions.setUser(result.user));
    };
}

// Other code such as selectors can use the imported `RootState` type
export const selectAuthed = (state: RootState) => state.app.authed;
export const selectUser = (state: RootState) => state.app.user;
export const selectClassicLinks = (state: RootState) => state.app.classicLinks;
export const selectSnack = (state: RootState) => ({ message: state.app.snackMessage, autoHideDuration: state.app.snackTimeout });

export default appSlice.reducer;