
import { iSFAkteneingaengeResult, iSFFristenResult, iSFLogoutResult, iSFUserResult, iSfAuthResult } from "./interfacesAPI";

const apiRoot = '/api/';

const options: RequestInit = {
    credentials: "include"
};

async function getF(path: string) {
    try {
        const response = await fetch(apiRoot + path, options);
        try {
            const data = await response.json();
            return data;
        }
        catch (e) {
            const text = await response.text();
            return {
                success: false,
                error: "no json",
                reply: text
            };
        }
    }
    catch (e) {
        return {
            success: false,
            error: "network issue",
            reply: e
        };
    }
}

function sfauth(): Promise<iSfAuthResult> {
    return getF("sfauth");
}

function logout(): Promise<iSFLogoutResult> {
    return getF("logout");
}

function loadUser(): Promise<iSFUserResult> {
    return getF("user");
}

function loadAkteneingaenge(): Promise<iSFAkteneingaengeResult> {
    return getF(`akteneingaenge`);
}

function loadFristen(bffId: string, days: number = 30): Promise<iSFFristenResult> {
    return getF(`fristen/${bffId}/${days}`);
}

const api = {
    apiRoot,
    sfauth,
    logout,
    loadUser,
    loadAkteneingaenge,
    loadFristen,
};

export default api;