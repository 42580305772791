import { Link } from "react-router-dom";
import Icon from "./Icon";

import './IconButton.css';

interface iIconButton {
    name: string;
    to?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    tabIndex?: number;
    light?: boolean;
}

export default function IconButton({ name, to, onClick, tabIndex, light }: iIconButton) {

    const classes = ["IconButton"];
    if (light) classes.push("light");

    const button = <button className={classes.join(" ")} onClick={onClick} tabIndex={tabIndex}>
        <Icon name={name} />
    </button>

    if (to) return (
        <Link to={to}>
            {button}
        </Link>
    );

    return button;
}