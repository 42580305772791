
import './Card.css';

interface iCard {
    style?: React.CSSProperties;
    title?: string;
    children?: React.ReactNode[] | React.ReactNode;
}

export function Card({ style, title, children }: iCard) {
    return (
        <div id={title?.replaceAll(" ", "")} className="Card" style={style}>
            {title && <h2 className="Card-Title">{title}</h2>}
            <div className='Card-Body'>
                {children}
            </div>
        </div>
    );
}