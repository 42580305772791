import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './store'
import api from '../API';
import { iSFAkteneingaenge } from '../interfaceSFClient';

// Define a type for the slice state
interface iEingaengeState {
    eingaenge: iSFAkteneingaenge[];
}

// Define the initial state using that type
const initialState: iEingaengeState = {
    eingaenge: []
}

export const eingaengeSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAkteneingaenge: (state, action: PayloadAction<iSFAkteneingaenge[]>) => {
            state.eingaenge = action.payload;
        }
    },
})

export const { setAkteneingaenge } = eingaengeSlice.actions;

export function loadAkteneingaenge() {
    return async function (dispatch: AppDispatch) {
        const result = await api.loadAkteneingaenge();
        if (result.success) dispatch(eingaengeSlice.actions.setAkteneingaenge(result.eingaenge));
    }
}

// Other code such as selectors can use the imported `RootState` type
export const selectAkteneingaenge = (state: RootState) => state.eingaenge.eingaenge;

export default eingaengeSlice.reducer;